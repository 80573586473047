import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./firebase";

import underscore from "vue-underscore";
Vue.use(underscore);

import axios from "axios";
axios.defaults.baseURL = "https://restoration-africa.firebaseio.com";

// paystack api
import paystack from "vue-paystack";
Vue.use(paystack);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
/* 
import hooper from "hooper";
import "hooper/dist/hooper.css";
Vue.use(hooper);
 */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <div>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <b-tabs>
          <b-tab title="Your Projects">
            <b-card>
              <div class="row">
                <div class="col-lg-12">
                  <h5 style="text-align: center;">
                    All projects will wrap up in October-November 2021
                    <small>(unless otherwise noted)</small>
                  </h5>
                </div>
                <hr />
                <b-card-group>
                  <div class="col-lg-4">
                    <b-card v-if="projects[0]">
                      <img
                        v-if="projects[0].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[0].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[0].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[0].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[0].return }}
                      <div
                        v-if="projects[0].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[1]">
                      <img
                        v-if="projects[1].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[1].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[1].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[1].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[0].return }}
                      <div
                        v-if="projects[1].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[2]">
                      <img
                        v-if="projects[2].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[2].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[2].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[2].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[2].return }}
                      <div
                        v-if="projects[2].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[3]">
                      <img
                        v-if="projects[3].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[3].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[3].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[3].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[3].return }}
                      <div
                        v-if="projects[3].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[4]">
                      <img
                        v-if="projects[4].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[4].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[4].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[4].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[4].return }}
                      <div
                        v-if="projects[4].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[5]">
                      <img
                        v-if="projects[5].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[5].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[5].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[5].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[5].return }}
                      <div
                        v-if="projects[5].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                </b-card-group>
              </div>
              <div class="row">
                <hr />
                <b-card-group deck> </b-card-group>
              </div>
              <div class="row">
                <hr />
                <b-card-group deck>
                  <div class="col-lg-4">
                    <b-card v-if="projects[6]">
                      <img
                        v-if="projects[6].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[6].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="
                          projects[6].type === 'Assorted Produce & Poultry'
                        "
                        src="../assets/veg.jpg"
                        alt="Produce & Poultry"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[6].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[6].return }}
                      <div
                        v-if="projects[6].type === 'Assorted Produce & Poultry'"
                      >
                        <hr />
                        <strong>End date: </strong>Q1 &mdash; 2022
                      </div>
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[7]">
                      <img
                        v-if="projects[7].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[7].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[7].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[7].return }}
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[8]">
                      <img
                        v-if="projects[8].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[8].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[8].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[8].return }}
                    </b-card>
                  </div>
                </b-card-group>
              </div>
              <div class="row">
                <hr />
                <b-card-group deck>
                  <div class="col-lg-4">
                    <b-card v-if="projects[9]">
                      <img
                        v-if="projects[9].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[9].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[9].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[9].return }}
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[10]">
                      <img
                        v-if="projects[10].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[10].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[10].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[10].return }}
                    </b-card>
                  </div>
                  <div class="col-lg-4">
                    <b-card v-if="projects[11]">
                      <img
                        v-if="projects[11].type === 'Cassava'"
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="99%"
                        height="41%"
                      />
                      <img
                        v-else-if="projects[11].type === 'Corn & Soy'"
                        src="../assets/corn-n-soy.jpeg"
                        alt="Corn & Soy"
                        width="99%"
                        height="41%"
                      />
                      <hr />
                      <strong>{{ projects[11].type }} </strong>
                      <hr />
                      <strong>Payout: </strong>{{ projects[11].return }}
                    </b-card>
                  </div>
                </b-card-group>
              </div>
            </b-card>
          </b-tab>
          <b-tab title="Available Projects">
            <b-card>
              <div style="background:#e1e1e1">
                <hr />
                <h4>Available Projects</h4>
                <hr />
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <b-card-group deck>
                    <b-card>
                      <h3>Cassava</h3>
                      <img
                        src="../assets/cassava.jpg"
                        alt="Cassava"
                        width="70%"
                        height="200px"
                      />
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12">
                            <h4>$300</h4>
                            <small
                              >The goal of this project is to finance small
                              businesses that produce food in rural Nigeria. By
                              renting a plot, you cover the start up costs.
                              After the first harvest, the farmers will have the
                              knowledge and finances to run their own farm
                              independently. This is benevolent, but is not
                              charity. Each plot is expected to make $1000 -
                              $1400, of which you will get 70%</small
                            >
                          </div>
                        </div>
                        <hr />
                        <div class="row text-center">
                          <div class="col-md-12">
                            Status: <strong>Ongoing</strong>
                          </div>
                        </div>
                        <hr />
                        <div class="col-lg-12" style="text-align: left;">
                          <form
                            action="https://formsubmit.co/8623fbedab5434756578934ade9675fe"
                            method="POST"
                          >
                            <input
                              type="hidden"
                              name="_next"
                              value="https://restoration.africa"
                            />
                            <input
                              type="hidden"
                              name="_captcha"
                              value="false"
                            />
                            <label for="number">
                              Number of plots: &nbsp;
                            </label>
                            <input
                              type="number"
                              name="number"
                              id="number"
                              required
                              value="1"
                              style="max-width: 100px !important;"
                            />
                            <br />
                            <label for="email">
                              Your email: &nbsp;
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              style="width: 75%"
                              required
                              placeholder="email"
                            />
                            <br />
                            <br />
                            <button
                              class="btn btn-danger"
                              onclick="showMessage()"
                              type="submit"
                            >
                              Reserve Yours
                            </button>
                            <br />
                            You'll receive an invoice if space is available.
                          </form>
                        </div>
                      </div>
                    </b-card>
                    <b-card>
                      <h3>Corn & Soy</h3>
                      <img
                        src="../assets/corn-n-soy.jpeg"
                        alt="Pig"
                        width="70%"
                        height="200px"
                      />
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <h4>$200</h4>
                          </div>
                        </div>
                        <hr />
                        <div class="row text-center">
                          <div class="col-md-12">
                            Status:
                            <strong>
                              Pre-orders start in February 2021
                            </strong>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </b-card>
          </b-tab>
          <b-tab title="Our Team">
            <b-card title="Farmers">
              <div class="row">
                <div class="col-lg-3">
                  <img
                    src="../assets/daira.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Daira Yaliu"
                  />
                  <br />
                  Daira Yaliu <br />
                  <small
                    >Age: 28 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/nathan.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Nathan Umur"
                  />
                  <br />
                  Nathan Umur <br />
                  <small
                    >Age: 23 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/abdulkahrim.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Abdulkahrim Mudi"
                  />
                  <br />
                  Abdulkahrim Mudi <br />
                  <small
                    >Age: 33 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/nav.png"
                    height="150px"
                    width="130px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Balley Yusuf"
                  />
                  <br />
                  Balley Yusuf <br />
                  <small
                    >Age: 42 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-3">
                  <img
                    src="../assets/nav.png"
                    height="150px"
                    width="130px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Umoru Shaibu"
                  />
                  <br />
                  Umoru Shaibu <br />
                  <small
                    >Age: 23 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/nav.png"
                    height="150px"
                    width="130px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Mark Ada"
                  />
                  <br />
                  Mark Ada <br />
                  <small
                    >Age: 56 <br />
                    Education: Primary School <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/nav.png"
                    height="150px"
                    width="130px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Illisu Adoga"
                  />
                  <br />
                  Illisu Adoga <br />
                  <small
                    >Age: 40 <br />
                    Education: None <br />
                    Town: Anyigba</small
                  >
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/nav.png"
                    height="150px"
                    width="130px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Jacob Oma"
                  />
                  <br />
                  Jacob Oma <br />
                  <small
                    >Age: 25 <br />
                    Education: Secondary School <br />
                    Town: Anyigba</small
                  >
                </div>
              </div>
            </b-card>
            <br />
            <b-card title="Team Leaders">
              <div class="row">
                <div class="col-lg-3">
                  <img
                    src="../assets/anu.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Musa Musa Adamu"
                  />
                  <br />
                  Musa Musa Adamu <br />
                  <small>Agronomist</small>
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/Isaac.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Isaac Wada"
                  />
                  <br />
                  Isaac Wada <br />
                  <small>Team Lead</small>
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/divine.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Divine Ameh"
                  />
                  <br />
                  Divine Ameh <br />
                  <small>Team Lead</small>
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/haruna.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Haruna Bash"
                  />
                  <br />
                  Haruna Bash <br />
                  <small>Marketing</small>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-3 offset-lg-3">
                  <img
                    src="../assets/ele.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Eleojo Musa"
                  />
                  <br />
                  Eleojo Musa <br />
                  <small>Legal</small>
                </div>
                <div class="col-lg-3">
                  <img
                    src="../assets/Muhammad.jpeg"
                    height="150px"
                    width="150px"
                    style="border: 1px solid;border-color: dimgray;"
                    alt="Muhammad Jamiu"
                  />
                  <br />
                  Muhammad Jamiu <br />
                  <small>Team Lead</small>
                </div>
              </div>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
/* import paystack from "vue-paystack"; */
import router from "@/router";

export default {
  /* components: {
    paystack
  }, 
  computed: {
    genReference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    }
  },*/
  data() {
    return {
      wallet: [],
      paystackkey: "pk_live_20586cfc252d3bf2d062198644d188074380513c", //paystack public key
      firstname: "",
      lastname: "",
      email: "",
      amountX: 40000,
      accessCode: "example-access-code",
      referenceX: "",
      reference15: "",
      reference60: "",
      reference150: "",
      ref: this.genReference,
      channels: ["card"],
      internet: null,
      projects: [],
      id: localStorage.getItem("userId")
    };
  },
  methods: {
    async creditBuyX() {
      const now = new Date();
      this.referenceX = localStorage.getItem("userId") + now.getTime();

      const res = await axios.get(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );

      let a = this.$_.toArray(res.data);
      let b = a.slice(-1).pop();
      let c = this.$_.values(b, "balance");
      let currentBalance = c[0] * 1;
      let newBal = parseInt(currentBalance) + this.amountX;
      axios.post(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token"),
        {
          balance: newBal,
          time: now
        }
      );
    },
    async buyGarri() {
      const now = new Date();
      const res = await axios.get(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      let a = this.$_.toArray(res.data);
      let b = a.slice(-1).pop();
      let c = this.$_.values(b, "balance");
      let currentBalance = c[0] * 1;
      let newBal = parseInt(currentBalance) - 300;

      if (currentBalance < 300) {
        alert("You don't have enough funds. Please top up.");
        return;
      } else {
        axios.post(
          "/wallets/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            balance: newBal,
            time: now
          }
        );
        axios.post(
          "/projects/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            type: "Cassava",
            time: now.toLocaleDateString(),
            price: "300",
            return: "$700-$900",
            duration: "10-11 months"
          }
        );
        alert("Thanks for your purchase");
        this.refresh();
      }
    },
    async buyCS() {
      const now = new Date();
      const res = await axios.get(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      let a = this.$_.toArray(res.data);
      let b = a.slice(-1).pop();
      let c = this.$_.values(b, "balance");
      let currentBalance = c[0] * 1;
      let newBal = parseInt(currentBalance) - 200;

      if (currentBalance < 200) {
        alert("You don't have enough funds. Please top up.");
        return;
      } else {
        axios.post(
          "/wallets/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            balance: newBal,
            time: now
          }
        );
        axios.post(
          "/projects/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            type: "Corn & Soy",
            time: now.toLocaleDateString(),
            price: "$200",
            return: "$275",
            duration: "6 months"
          }
        );
        alert("Thanks for your purchase");
        this.refresh();
      }
    },
    /* async buyFarm() {
      const now = new Date();
      const res = await axios.get(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      let a = this.$_.toArray(res.data);
      let b = a.slice(-1).pop();
      let c = this.$_.values(b, "balance");
      let currentBalance = c[0] * 1;
      let newBal = parseInt(currentBalance) - 400000;

      if (currentBalance < 400000) {
        alert("You don't have enough funds. Please top up.");
        return;
      } else {
        axios.post(
          "/wallets/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            balance: newBal,
            time: now
          }
        );
        axios.post(
          "/projects/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token"),
          {
            type: "Farm",
            units: 1,
            time: now.toLocaleDateString(),
            price: "₦2,000,000",
            return: "Ownership",
            payments: 1,
            duration: "Owner"
          }
        );
        alert("Thanks for your purchase");
        this.refresh();
      }
    }, */
    callback: function(response) {
      console.log(response);
    },
    close: function() {
      console.log("Payment closed");
    },
    async refresh() {
      try {
        const res = await axios.get(
          "/wallets/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            localStorage.getItem("token")
        );
        let a = this.$_.toArray(res.data);
        let b = a.slice(-1).pop();
        let c = this.$_.values(b, "balance");
        this.wallet = (c[0] * 1).toLocaleString();
      } catch (error) {
        this.wallet = "- [Session expired. Please login again to authenticate]";
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        router.replace("/login");
      }
    }
  },
  async created() {
    try {
      const res = await axios.get(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      //let i = this.$_.keys(res.data)
      let a = this.$_.toArray(res.data);
      let b = a.slice(-1).pop();
      let c = this.$_.values(b, "balance");
      this.wallet = (c[0] * 1).toLocaleString();
    } catch (e) {
      console.error(e);
    }

    let x = await axios.get(
      "/users/" +
        localStorage.getItem("userId") +
        ".json?auth=" +
        localStorage.getItem("token")
    );
    let y = this.$_.toArray(x.data);
    this.firstname = y[0].firstName;
    this.lastname = y[0].lastName;
    this.email = y[0].email;

    try {
      const res = await axios.get(
        "/projects/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      let a = this.$_.toArray(res.data);
      this.projects = a;
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.refresh();

    setInterval(
      function() {
        this.refresh();
      }.bind(this),
      15000
    );
  }
};
</script>
<style scoped></style>

<template>
  <div id="app" class="container">
    <div id="nav">
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand
          ><img alt="Restoration" src="./assets/nav.png" width="30px"
        /></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item><router-link :to="'/'">Home</router-link></b-nav-item>
            <b-nav-item
              ><router-link :to="'dashboard'"
                >Dashboard</router-link
              ></b-nav-item
            >
            <b-nav-item
              ><router-link :to="'login'">Login</router-link></b-nav-item
            >
            <b-nav-item
              ><router-link :to="'register'">Register</router-link></b-nav-item
            >
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item right
              ><button v-if="loggedIn" class="btn btn-info" @click="logout()">
                Logout
              </button></b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <router-view />
    <footer>
      <hr />
      <a href="mailto:jacob@restoration.africa"
        ><b-icon-envelope-fill></b-icon-envelope-fill> &nbsp;
        jacob@restoration.africa</a
      >
      <hr />
      Restoration Africa is a registered company in Nigeria. No. RC1681007 — We
      respect your privacy and use some data for analytics. By using this site,
      you accept. COPYRIGHT © 2022. ALL RIGHTS RESERVED.
    </footer>
  </div>
</template>

<script>
import router from "./router";

export default {
  data() {
    return {
      loggedIn: null
    };
  },
  created() {
    this.$store.dispatch("tryAutoLogin");
  },
  methods: {
    logout() {
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      router.replace("/");
      this.loggedIn = false;
    },
    authStatus() {
      if (localStorage.getItem("token")) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    }
  },
  async mounted() {
    this.authStatus();

    setInterval(
      function() {
        this.authStatus();
      }.bind(this),
      1000
    );
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
a {
  color: #525e41;
}
</style>

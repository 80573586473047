import Vue from "vue";
import Vuex from "vuex";
import globalaxios from "axios";
import axios from "../axios-auth";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
    },
    storeUser(state, user) {
      state.user = user;
    },
    clearAuthData(state) {
      state.idToken = null;
      state.userId = null;
    }
  },
  actions: {
    setLogoutTimer({ commit }, expirationTime) {
      setTimeout(() => {
        commit("clearAuthData");
      }, expirationTime * 1000);
    },
    signup({ commit, dispatch }, authData) {
      let now = new Date();
      axios
        .post("/signupNewUser?key=AIzaSyCge1AmJqjjbtH2n_9To81mT12wWHCWDaY", {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log(res);
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId
          });

          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch("storeUser", authData);
          dispatch("setLogoutTimer", res.data.expiresIn);
          router.replace("/dashboard");
        });
      globalaxios.post(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json" +
          "?auth=" +
          localStorage.getItem("token"),
        {
          time: now,
          balance: 0
        }
      );
    },
    login({ commit, dispatch }, authData) {
      axios
        .post("/verifyPassword?key=AIzaSyCge1AmJqjjbtH2n_9To81mT12wWHCWDaY", {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("expirationDate", expirationDate);
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId
          });
          dispatch("setLogoutTimer", res.data.expiresIn);
          router.replace("/dashboard");
        })
        .catch(error => console.log(error));
      /* setInterval(() => {
        alert("Your session has expired. Please login again.");
        commit("clearAuthData");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        router.replace("/");
      }, 60000 * 30); // 30 mins to logout */
    },
    tryAutoLogin({ commit }) {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const expirationDate = localStorage.getItem("expirationDate");
      const now = new Date();
      if (now >= expirationDate) {
        return;
      }
      const userId = localStorage.getItem("userId");
      commit("authUser", {
        token: token,
        userId: userId
      });
      router.replace("/");
    },
    logout({ commit }) {
      commit("clearAuthData");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      router.replace("/");
    },
    storeUser(state, storeData) {
      const now = new Date();
      const userId = localStorage.getItem("userId");
      globalaxios.post(
        "/users/" +
          localStorage.getItem("userId") +
          ".json" +
          "?auth=" +
          localStorage.getItem("token"),
        {
          email: storeData.email,
          id: userId,
          createTime: now,
          firstName: localStorage.getItem("firstName"),
          lastName: localStorage.getItem("lastName"),
          country: localStorage.getItem("country")
        }
      );
      globalaxios.post(
        "/wallets/" +
          localStorage.getItem("userId") +
          ".json" +
          "?auth=" +
          localStorage.getItem("token"),
        {
          time: now,
          balance: 0
        }
      );
    },
    fetchUser({ commit, state }) {
      axios
        .get(
          "/users/" +
            localStorage.getItem("userId") +
            ".json?auth=" +
            state.idToken
        )
        .then(res => {
          const data = res.data;
          const users = [];
          for (let key in data) {
            const user = data[key];
            user.id = key;
            users.push(user);
          }
          commit("storeUser", users[0]);
        });
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.idToken !== null;
    }
  },
  modules: {}
});

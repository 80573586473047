<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4>
          The Harvest is Underway! &mdash; We'll be reaching out to sponsors in
          the coming weeks
        </h4>
        <img src="../assets/cassava.jpeg" width="100%" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App"
};
</script>

<style scoped>
.card {
  border: 1px solid;
  padding: 10px;
  box-shadow: 1px 2px #d4d3c8;
  vertical-align: middle;
}
#no-shadow {
  border: 1px solid;
  padding: 10px;
  box-shadow: 1px 2px #fff;
  vertical-align: middle;
}
</style>

<template>
  <div class="container">
    <hr />
    <img alt="Restoration logo" src="../assets/logo.png" />
    <hr />
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs card pills active-nav-item-class="btn-dark">
            <b-tab title="Login"
              ><b-card title="Login">
                <form @submit.prevent="onLogin">
                  <div class="form-row" style="text-align: left;">
                    <div class="form-group col-md-12">
                      <label for="loginEmail">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="loginEmail "
                        v-model="loginEmail"
                        placeholder="you@email.com"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-row" style="text-align: left;">
                    <div class="form-group col-md-12">
                      <label for="loginPassword">Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="loginPassword"
                        v-model="loginPassword"
                        placeholder="password"
                      />
                    </div>
                  </div>
                  <div class="form-row" style="text-align: left;">
                    <button type="submit" class="btn btn-secondary">
                      Login
                    </button>
                  </div>
                </form>
              </b-card></b-tab
            >

            <b-tab title="Forgot Password?">
              <form @submit.prevent="resetPassword">
                <div class="form-row" style="text-align: left;">
                  <div class="form-group col-md-12">
                    <label for="resetEmail">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="resetEmail "
                      v-model="resetEmail"
                      placeholder="you@email.com"
                      required
                    />
                  </div>
                </div>
                <div class="form-row" style="text-align: left;">
                  <button type="submit" class="btn btn-secondary">
                    Send Email to Reset Password
                  </button>
                </div>
              </form>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import db from "firebase";

export default {
  data() {
    return {
      loginEmail: null,
      loginPassword: null,
      resetEmail: null
    };
  },
  methods: {
    ...mapActions({
      login: "../store/login",
      tryAutoLogin: "../store/tryAutoLogin",
      fetchUser: "../store/fetchUser",
      storeUsers: "../store/storeUsers"
    }),
    onLogin() {
      const formData = {
        email: this.loginEmail,
        password: this.loginPassword
      };
      this.$store.dispatch("login", {
        email: formData.email,
        password: formData.password
      });
      this.$router.push("/dashboard");
    },
    resetPassword() {
      const auth = db.auth();
      auth
        .sendPasswordResetEmail(this.resetEmail)
        .then(() => {
          alert("Email sent!");
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
    }
  },
  created() {
    this.$store.dispatch("fetchUser");
  }
};
</script>

<style lang="css" scoped></style>

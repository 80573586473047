<template>
  <div>
    <h1>These are your projects</h1>
    <div class="container">
      <div class="row">
        <hr />
        <b-card-group deck>
          <div class="col-lg-4">
            <b-card v-if="projects[0]">
              <img
                v-if="projects[0].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[0].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[0].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[0].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[0].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[0].time }}
              <hr />
              <strong>Price: </strong>{{ projects[0].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[1]">
              <img
                v-if="projects[1].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[1].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[1].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[1].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[1].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[1].time }}
              <hr />
              <strong>Price: </strong>{{ projects[1].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[2]">
              <img
                v-if="projects[2].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[2].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[2].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[2].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[2].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[2].time }}
              <hr />
              <strong>Price: </strong>{{ projects[2].price }}
            </b-card>
          </div>
        </b-card-group>
      </div>
      <div class="row">
        <hr />
        <b-card-group deck>
          <div class="col-lg-4">
            <b-card v-if="projects[3]">
              <img
                v-if="projects[3].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[3].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[3].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[3].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[3].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[3].time }}
              <hr />
              <strong>Price: </strong>{{ projects[3].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[4]">
              <img
                v-if="projects[4].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[4].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[4].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[4].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[4].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[4].time }}
              <hr />
              <strong>Price: </strong>{{ projects[4].price }}
              <strong></strong>
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[5]">
              <img
                v-if="projects[5].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[5].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[5].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[5].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[5].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[5].time }}
              <hr />
              <strong>Price: </strong>{{ projects[5].price }}
            </b-card>
          </div>
        </b-card-group>
      </div>
      <div class="row">
        <hr />
        <b-card-group deck>
          <div class="col-lg-4">
            <b-card v-if="projects[6]">
              <img
                v-if="projects[6].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[6].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[6].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[6].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[6].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[6].time }}
              <hr />
              <strong>Price: </strong>{{ projects[6].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[7]">
              <img
                v-if="projects[7].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[7].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[7].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[7].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[7].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[7].time }}
              <hr />
              <strong>Price: </strong>{{ projects[7].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[8]">
              <img
                v-if="projects[8].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[8].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[8].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[8].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[8].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[8].time }}
              <hr />
              <strong>Price: </strong>{{ projects[8].price }}
            </b-card>
          </div>
        </b-card-group>
      </div>
      <div class="row">
        <hr />
        <b-card-group deck>
          <div class="col-lg-4">
            <b-card v-if="projects[9]">
              <img
                v-if="projects[9].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[9].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[9].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[9].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[9].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[9].time }}
              <hr />
              <strong>Price: </strong>{{ projects[9].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[10]">
              <img
                v-if="projects[10].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[10].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              <strong>Project Type: </strong>{{ projects[10].type }}
              <hr />
              <strong>Duration: </strong>{{ projects[10].duration }}
              <hr />
              <strong>Expected return: </strong>{{ projects[10].return }}
              <hr />
              <strong>Project Start: </strong>{{ projects[10].time }}
              <hr />
              <strong>Price: </strong>{{ projects[10].price }}
            </b-card>
          </div>
          <div class="col-lg-4">
            <b-card v-if="projects[11]">
              <img
                v-if="projects[11].type === 'Moringa'"
                src="../assets/leaves.jpg"
                alt="Moringa"
                width="95%"
                height="41%"
              />
              <img
                v-else-if="projects[11].type === 'Vegetables & Poultry'"
                src="../assets/veg.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <img
                v-else
                src="../assets/chicken.jpg"
                alt="Poultry"
                width="95%"
                height="41%"
              />
              <hr />
              {{ projects[11].type }}
              <hr />
              <strong>{{ projects[11].duration }}</strong>
              <hr />
              <strong>Expected return: {{ projects[11].return }}</strong>
              <hr />
              <strong>{{ projects[11].time }}</strong>
              <hr />
              <strong>{{ projects[11].price }}</strong>
              <strong></strong>
            </b-card>
          </div>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      projects: []
    };
  },
  async created() {
    try {
      const res = await axios.get(
        "/projects/" +
          localStorage.getItem("userId") +
          ".json?auth=" +
          localStorage.getItem("token")
      );
      let a = this.$_.toArray(res.data);
      this.projects = a;
    } catch (e) {
      console.error(e);
    }
  }
};
</script>

<style scoped>
.card {
  background: linear-gradient(
    113deg,
    rgba(209, 205, 191, 0.4415967070421919) 0%,
    rgba(246, 242, 216, 0.6600841020001751) 5%,
    rgba(236, 226, 168, 0.5256303204875701) 12%,
    rgba(246, 245, 239, 0.10546225326067926) 100%
  );
}
</style>

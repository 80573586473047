import Vue from "vue";
import VueRouter from "vue-router";
import Frontpage from "../views/Frontpage.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import About from "../views/About.vue";
import Projects from "../views/Projects.vue";
//import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/",
    name: "Frontpage",
    component: Frontpage
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (localStorage.getItem("token")) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

import { initializeApp } from "firebase";
const app = initializeApp({
  apiKey: "AIzaSyCge1AmJqjjbtH2n_9To81mT12wWHCWDaY",
  authDomain: "restoration-africa.firebaseapp.com",
  databaseURL: "https://restoration-africa.firebaseio.com",
  projectId: "restoration-africa",
  storageBucket: "restoration-africa.appspot.com",
  messagingSenderId: "1037735603683",
  appId: "1:1037735603683:web:18e23fcbfda357646cc4d6",
  measurementId: "G-E2Y397DSXD"
});

export const db = app.database();

//export const userRef = db.ref("users");
